import { memo, useEffect, useMemo, useState } from 'react'

import type { OrderBy, OrderType } from '@shared/api/rtkQuery'
import {
  INITIAL_PAGINATION_RESPONSE,
  useGetWorkflowPreviewStatisticSummaryQuery,
} from '@shared/api/rtkQuery'
import deleteEmptyKeys from '@shared/lib/utils/deleteEmptyKeys'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { DataTable } from '@shared/ui/table'
import {
  useSetSharedState,
  useSharedState,
} from '@widgets/insight/lib/SharedStateContext'

import columns from './columns'

const WorkflowsInsightWithCampaignDataTable = () => {
  const sharedState = useSharedState()
  const setSharedState = useSetSharedState()
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [orderBy, setOrderBy] = useState<OrderBy>()
  const [orderType, setOrderType] = useState<OrderType>('asc')

  const { data = INITIAL_PAGINATION_RESPONSE, isFetching } =
    useGetWorkflowPreviewStatisticSummaryQuery(
      deleteEmptyKeys({
        filterParams: {
          page,
          perPage,
          orderBy,
          orderType,
          timeRangeParams: sharedState.timeRangeParams,
        },
        matchingParams: {
          title: sharedState.searchText,
        },
      })
    )

  useEffect(() => setPage(DEFAULT_PAGE), [perPage])
  useEffect(() => {
    setSharedState(prev => ({
      ...prev,
      isLoading: isFetching,
      exportParams: {
        filterParams: {
          page,
          perPage,
          orderBy,
          orderType,
          timeRangeParams: sharedState.timeRangeParams,
        },
        matchingParams: { title: sharedState.searchText },
      },
    }))
  }, [
    isFetching,
    orderBy,
    orderType,
    page,
    perPage,
    setSharedState,
    sharedState.searchText,
    sharedState.timeRangeParams,
  ])

  const tableColumns = useMemo(() => columns(), [])

  const handleSorting = (orderBy: string, orderType: string) => {
    setOrderBy(orderBy as OrderBy)
    setOrderType(orderType as OrderType)
    setPage(DEFAULT_PAGE)
    setPerPage(DEFAULT_PER_PAGE)
  }

  return (
    <DataTable
      columns={tableColumns}
      rows={isFetching ? [] : data.items}
      count={data.totalCount}
      page={page - 1}
      rowsPerPage={perPage}
      isLoading={isFetching}
      onSorting={handleSorting}
      onPageChange={newPage => {
        setPage(newPage + 1)
      }}
      onRowsPerPageChange={setPerPage}
    />
  )
}

export default memo(WorkflowsInsightWithCampaignDataTable)
