import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  INITIAL_PAGINATION_RESPONSE,
  useGetCampaignPreviewStatisticSummaryExportMutation,
  useGetCampaignPreviewStatisticSummaryQuery,
} from '@shared/api/rtkQuery'
import type { OrderBy, OrderType } from '@shared/api/rtkQuery/insight/type'
import { useAppDispatch } from '@shared/lib/hooks'
import { trackEvent } from '@shared/lib/utils/amplitude'
import deleteEmptyKeys from '@shared/lib/utils/deleteEmptyKeys'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { openToast } from '@shared/model/slices'
import { DataTable } from '@shared/ui/table'
import { useSharedState } from '@widgets/insight/lib/SharedStateContext'
import { downloadInsightCSV } from '@widgets/insight/lib/utils'
import Head from '@widgets/insight/ui/_shared/Head'

import columns from './columns'

const CampaignInsightDataTable = () => {
  const { t } = useTranslation(['common', 'table'])
  const sharedState = useSharedState()
  const dispatch = useAppDispatch()

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [orderBy, setOrderBy] = useState<OrderBy>('sent_uu_count')
  const [orderType, setOrderType] = useState<OrderType>('desc')

  const { data = INITIAL_PAGINATION_RESPONSE, isFetching } =
    useGetCampaignPreviewStatisticSummaryQuery(
      deleteEmptyKeys({
        filterParams: {
          page,
          perPage,
          orderBy,
          orderType,
          timeRangeParams: sharedState.timeRangeParams,
        },
        matchingParams: {
          title: sharedState.searchText,
          mediumType: sharedState.mediumType,
        },
      })
    )

  const [exportCampaignPreviewStatisticSummary, { isLoading: isExporting }] =
    useGetCampaignPreviewStatisticSummaryExportMutation()

  useEffect(() => setPage(DEFAULT_PAGE), [perPage])

  const handleSorting = (orderBy: string, orderType: string) => {
    setOrderBy(orderBy as OrderBy)
    setOrderType(orderType as OrderType)
    setPage(DEFAULT_PAGE)
    setPerPage(DEFAULT_PER_PAGE)
  }

  const handleExportInsight = async () => {
    try {
      const filename = await exportCampaignPreviewStatisticSummary(
        deleteEmptyKeys({
          filterParams: {
            page,
            perPage,
            orderBy,
            orderType,
            timeRangeParams: sharedState.timeRangeParams,
          },
          matchingParams: {
            title: sharedState.searchText,
            mediumType: sharedState.mediumType,
          },
        })
      ).unwrap()

      await downloadInsightCSV(filename, 'campaign_insight')
    } catch (_e) {
      dispatch(
        openToast({
          message: t('common:failure_export'),
          status: 'error',
        })
      )
    }
  }

  const tableColumns = useMemo(() => columns(t), [t])

  useEffect(() => {
    trackEvent('CampaignPerformanceSummaryViewed')
  }, [])

  useEffect(() => {
    const [startDate, endDate] = sharedState.timeRangeParams as string[]

    trackEvent('CampaignPerformanceSummaryFiltered', {
      filterEndDate: endDate,
      filterStartDate: startDate,
      filterText: sharedState.searchText,
      mediumType: sharedState.mediumType,
    })
  }, [sharedState])

  return (
    <>
      <Head
        title={t('common:route.campaign_insight')}
        hasMediumTypeFilter
        onExport={handleExportInsight}
        isExportDisabled={isExporting || isFetching}
      />
      <DataTable
        columns={tableColumns}
        rows={isFetching ? [] : data.items}
        count={data.totalCount}
        page={page - 1}
        rowsPerPage={perPage}
        isLoading={isFetching}
        onSorting={handleSorting}
        defaultSortBy="sent_uu_count"
        defaultSortDirection="desc"
        onPageChange={newPage => {
          setPage(newPage + 1)
        }}
        onRowsPerPageChange={setPerPage}
      />
    </>
  )
}

export default memo(CampaignInsightDataTable)
