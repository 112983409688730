import deleteEmptyKeys from '@shared/lib/utils/deleteEmptyKeys'
import { getBrowserTimezone } from '@shared/lib/utils/time'

import {
  api,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type {
  CampaignInsight,
  Insight,
  InsightExport,
  InsightParams,
  InsightSummary,
  MatchingParams,
  WorkflowInsightDetail,
  WorkflowSubscriptionInsight,
  WorkflowSubscriptionInsightDetail,
  WorkflowSubscriptionInsightDetailParams,
  WorkflowSubscriptionInsightParams,
} from './type'

export const INIT_INSIGHT_DATA: InsightSummary = {
  statisticSummaries: [],
}

export const EMPTY_INSIGHT_SUMMARY_DATA: InsightSummary['statisticSummaries'][number] =
  {
    id: 0,
    title: '',
    groupByValues: [],
    msgChannelTitle: '',
    sentUuCount: 0,
    receptionUuCount: 0,
    receptionUuCountRate: 0,
    openUuCount: 0,
    openUuPerSent: 0,
    bounceUuCount: 0,
    bounceUuPerSent: 0,
    complaintUuCount: 0,
    complaintUuPerSent: 0,
    clickUuCount: 0,
    clickUuPerSent: 0,
    conversionUuCount: 0,
    conversionUuPerSent: 0,
    conversionValue: 0,
    conversionValuePerSent: 0,
    mediumType: undefined,
    workflowLastEnabledAt: {
      valid: false,
      time: '',
    },
  }

export const getConversionsInsight = ({
  conversionEventEnable,
  conversionEvent,
  conversionEventField,
}: {
  conversionEventEnable: boolean
  conversionEvent: string
  conversionEventField: string
}): {
  conversionEvent: string
  conversionEventField?: string
} | null => {
  if (!conversionEventEnable) {
    return null
  } else if (!conversionEventField) {
    return {
      conversionEvent,
    }
  } else {
    return { conversionEvent, conversionEventField }
  }
}

const ConversionWindowMinutes = 10080 /** conversion_window_minutes is fixed in 10080 (7 days) in the first version */
const AttributionModel =
  'last_click' /** first_click, linear, time_decay are coming in the future */
const Timezone = getBrowserTimezone()

const insight = api.injectEndpoints({
  endpoints: builder => ({
    getCampaignPreviewStatisticSummary: builder.query<
      Insight,
      { filterParams: InsightParams; matchingParams?: MatchingParams }
    >({
      query: ({ filterParams, matchingParams }) => ({
        method: 'POST',
        url: `campaign_events/campaign_preview_statistic_summary`,
        body: deleteEmptyKeys({
          filter: {
            attribution_model: AttributionModel,
            conversion_window_minutes: ConversionWindowMinutes,
            time_range_time_zone: Timezone,
            time_range_type: 'absolute_between',
            ...filterParams,
          },
          matching: matchingParams,
        }),
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(
          res as PaginationResponse<CampaignInsight & WorkflowInsightDetail>
        ),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'CampaignInsight' as const,
                id,
              })),
              'CampaignInsight',
            ]
          : ['CampaignInsight'],
    }),
    getCampaignPreviewStatisticSummaryExport: builder.mutation<
      InsightExport,
      { filterParams: InsightParams; matchingParams?: MatchingParams }
    >({
      query: ({ filterParams, matchingParams }) => ({
        method: 'POST',
        url: `campaign_events/campaign_preview_statistic_summary/export`,
        body: deleteEmptyKeys({
          filter: {
            attribution_model: AttributionModel,
            conversion_window_minutes: ConversionWindowMinutes,
            time_range_time_zone: Timezone,
            time_range_type: 'absolute_between',
            ...filterParams,
          },
          matching: matchingParams,
        }),
      }),
    }),
    getWorkflowPreviewStatisticSummary: builder.query<
      Insight,
      { filterParams: InsightParams; matchingParams?: MatchingParams }
    >({
      query: ({ filterParams, matchingParams }) => ({
        method: 'POST',
        url: `campaign_events/workflow_preview_statistic_summary`,
        body: deleteEmptyKeys({
          filter: {
            attribution_model: AttributionModel,
            conversion_window_minutes: ConversionWindowMinutes,
            time_range_time_zone: Timezone,
            time_range_type: 'absolute_between',
            ...filterParams,
          },
          matching: matchingParams,
        }),
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(
          res as PaginationResponse<CampaignInsight & WorkflowInsightDetail>
        ),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'WorkflowsInsight' as const,
                id,
              })),
              'WorkflowsInsight',
            ]
          : ['WorkflowsInsight'],
    }),
    getWorkflowPreviewStatisticSummaryExport: builder.mutation<
      InsightExport,
      { filterParams: InsightParams; matchingParams?: MatchingParams }
    >({
      query: ({ filterParams, matchingParams }) => ({
        method: 'POST',
        url: `campaign_events/workflow_preview_statistic_summary/export`,
        body: deleteEmptyKeys({
          filter: {
            attribution_model: AttributionModel,
            conversion_window_minutes: ConversionWindowMinutes,
            time_range_time_zone: Timezone,
            time_range_type: 'absolute_between',
            ...filterParams,
          },
          matching: matchingParams,
        }),
      }),
    }),
    getWorkflowInsightDetail: builder.query<InsightSummary, InsightParams>({
      query: payload => ({
        method: 'POST',
        url: `campaign_events/preview_statistic_summary`,
        body: {
          filter: {
            source_type: 'workflow',
            attribution_model: AttributionModel,
            conversion_window_minutes: ConversionWindowMinutes,
            time_range_time_zone: Timezone,
            time_range_type: 'absolute_between',
            // 目前支援到 campaign_events
            group_by_fields: [
              {
                source: 'campaign_events',
                field: 'medium_type',
              },
              {
                source: 'campaign_events',
                field: 'third_party_app_integration_type',
              },
            ],
            ...payload,
          },
        },
      }),

      transformResponse: data =>
        transformResponseCamelCaseKeys(data as InsightSummary),
      providesTags: ['WorkflowInsightDetail'], // api沒有分頁, 只用一個tag
    }),
    getCampaignInsightDetail: builder.query<InsightSummary, InsightParams>({
      query: payload => ({
        method: 'POST',
        url: `campaign_events/preview_statistic_summary`,
        body: {
          filter: {
            source_type: 'campaign',
            attribution_model: AttributionModel,
            conversion_window_minutes: ConversionWindowMinutes,
            time_range_type: 'absolute_between',
            time_range_time_zone: Timezone,
            // 目前支援到 campaign_events
            group_by_fields: [
              {
                source: 'campaign_events',
                field: 'medium_type',
              },
            ],
            ...payload,
          },
        },
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as InsightSummary),
      providesTags: ['CampaignInsightDetail'], // api沒有分頁, 只用一個tag
    }),
    getWorkflowSubscriptionInsightDetail: builder.query<
      WorkflowSubscriptionInsightDetail[],
      WorkflowSubscriptionInsightDetailParams
    >({
      query: body => ({
        method: 'POST',
        url: `workflows/preview_subscription_statistic_summary`,
        body,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(
          data as WorkflowSubscriptionInsightDetail[]
        ),
      providesTags: ['WorkflowSubscriptionInsightDetail'], // api沒有分頁, 只用一個tag
    }),
    useGetWorkflowSubscriptionInsightQuery: builder.query<
      PaginationResponse<WorkflowSubscriptionInsight>,
      WorkflowSubscriptionInsightParams
    >({
      query: body => ({
        method: 'POST',
        url: `workflows/preview_workflows_subscription_statistic_summary`,
        body,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(
          data as PaginationResponse<WorkflowSubscriptionInsight>
        ),
      providesTags: ['WorkflowSubscriptionInsight'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCampaignPreviewStatisticSummaryQuery,
  useGetWorkflowPreviewStatisticSummaryQuery,
  useGetWorkflowInsightDetailQuery,
  useGetCampaignInsightDetailQuery,
  useGetWorkflowSubscriptionInsightDetailQuery,
  useUseGetWorkflowSubscriptionInsightQueryQuery,
  useGetCampaignPreviewStatisticSummaryExportMutation,
  useGetWorkflowPreviewStatisticSummaryExportMutation,
} = insight
