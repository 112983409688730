import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import DateRangePicker, { DatePickerWrapper } from '@shared/ui/DateRangePicker'
import { UniIcon as Icon } from '@shared/ui/icons'
import { SearchBar } from '@shared/ui/searchInput'
import theme from '@theme'
import {
  createInitState,
  useSetSharedState,
  useSharedState,
} from '@widgets/insight/lib/SharedStateContext'

import ExportCSVButton from './ExportCSVButton'
import MediumTypeFilter from './MediumTypeFilter'
import WorkflowSubscriptionFilter from './WorkflowSubscriptionFilter'

type Props = {
  hasMediumTypeFilter?: boolean
  hasWorkflowOnlyWithSubscriptionFilter?: boolean
  title: string
  onExport?: () => void
  isExportDisabled?: boolean
}

const Head = ({
  hasMediumTypeFilter = false,
  hasWorkflowOnlyWithSubscriptionFilter = false,
  title,
  onExport,
  isExportDisabled = false,
}: Props) => {
  const { t } = useTranslation(['common', 'insight'])
  const sharedState = useSharedState()
  const setSharedState = useSetSharedState()

  // 保持原本的行為：時間區間不改變，但是其他條件要清空。
  useEffect(() => {
    setSharedState(prev => ({
      ...createInitState(),
      timeRangeParams: prev.timeRangeParams,
    }))
  }, [setSharedState, title])

  const handleExport = () => onExport?.()

  return (
    <>
      <Breadcrumbs>
        <Box>{title}</Box>
      </Breadcrumbs>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Box display="flex" alignItems="center">
          {hasMediumTypeFilter && (
            <MediumTypeFilter
              onChange={mediumType =>
                setSharedState(prev => ({ ...prev, mediumType }))
              }
            />
          )}
          {hasWorkflowOnlyWithSubscriptionFilter && (
            <WorkflowSubscriptionFilter
              onChange={filterType =>
                setSharedState(prev => ({
                  ...prev,
                  filterType,
                }))
              }
            />
          )}
          <Box display="flex" alignItems="center">
            <Icon
              icon={ICON.schedule}
              color={theme.colors.brightBlue}
              fontSize="small"
            />
            <DatePickerWrapper>
              <DateRangePicker
                values={sharedState.timeRangeParams}
                durationBgColor={theme.colors.white}
                onChange={timeRangeParams =>
                  setSharedState(prev => ({ ...prev, timeRangeParams }))
                }
                isHideDynamicTab
              />
            </DatePickerWrapper>
          </Box>
        </Box>
        <Box display="flex">
          <ExportCSVButton
            onExport={handleExport}
            isExportDisabled={isExportDisabled}
          />
          <SearchBar
            defaultValue={sharedState.searchText}
            onSearch={searchText =>
              setSharedState(prev => ({ ...prev, searchText }))
            }
            placeholder={t('common:search')}
          />
        </Box>
      </Box>
    </>
  )
}

export default memo(Head)
